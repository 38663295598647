<style lang="scss">

.presentation-editions {
  width: 100%;
  height: calc(100vh - 120px);

  @include tablet {
    // height: 280px !important;
    padding: 0 60px;
  }

  .presentation__content {
    text-align: left;
    position: relative;
    top: 150px;
    z-index: 100 !important;

    @include tablet {
      top: 70px;
    }

    &-title {
      font-size: 4.5rem;
      line-height: 82px;
      font-weight: 500;
      margin-top: 30px;
      letter-spacing: 2px;
      text-transform: uppercase;
      font-family: $title-font;
      z-index: 1;
      /* -webkit-text-stroke: 1px black; */
      text-shadow: -1px 0 black;

      @include tablet {
        font-size: 50px;
        line-height: 60px;
      }

      span {
        display: block;
        font-weight: bold;
      }
    }

    &-roll {
      text-align: center;
      font-weight: 100;
      height: 20px;
      position: absolute;
      margin: 0 auto;
      left: 50%;
      top: 300px;

      .arrow {
        width: 16px;
        vertical-align: middle;
        margin-left: 5px;
      }
    }
  }

  &__img-builds {
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    animation: down-up 1s ease-out;
  }

  @keyframes down-up {
    0% {
      opacity: 0;
      transform: translateY(100px);
    }
    100% {
      opacity: 1;
      transform: translateY(0px);
    }
  }

  @keyframes slide-in {
    0% {
      transform: translateX(100px);
    }
    100% {
      transform: translateX(0px);
    }
  }

  @keyframes shows-up {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

}

</style>

<template lang="pug">
  .presentation-editions
    //.presentation__img(:style="getBackground")
    .presentation__content
      Title Explore nossa história
      //span.presentation__content-subtitle Explore nossa história
      h1.presentation__content-title Edições
      p.presentation__content-roll Role a página
        img.arrow(src="@/assets/img/editions/down-arrow.svg")

    img.presentation-editions__img-builds(src="@/assets/img/editions/predios.webp")
</template>

<script>

export default {
  name: 'Presentation',

  methods: {
    getBackground(index) {
      return {
        backgroundImage: `linear-gradient(180deg, transparent, black), url(${this.slides[index].image}) `
      };
    }
  }

};

</script>
