<style lang="scss">

  .editions-index {
    text-align: center;
  }

</style>

<template lang="pug">

  .editions-index
    .container
      Presentation

      CardNews(v-if="cards[0]" :card='cards[0]' typeCard="pdf")

      CardNews(v-if="cards[1]" :card='cards[1]' flex-order="right" typeCard="pdf")

      CardNews(v-if="cards[2]" :card='cards[2]' typeCard="pdf")

</template>

<script>
import Presentation from './components/presentation.vue';

export default {
  name: 'Editions',

  components: {
    Presentation
  },

  data() {
    return {
      cards: [],
      mock: [{
        category: 'Nessa edição',
        title: 'Programa Quero Ser P.O.',
        description: '<p>Como esse programa incentivou e capitou diversos profissionais para várias unidades da compania.</p> <p>Aumentando a qualidade dos nosso projetos e produtos</p>',
        image: 'https://cdn-1.motorsport.com/images/amp/6n9kK8XY/s1000/daniel-ricciardo-mclaren-mcl35.jpg',
        link: '/'
      },
      {
        category: 'Nessa edição',
        title: 'Programa Quero Ser P.O.',
        description: '<p>Como esse programa incentivou e capitou diversos profissionais para várias unidades da compania.</p> <p>Aumentando a qualidade dos nosso projetos e produtos</p>',
        image: 'https://media.racingonline.com.br/uploads/2021/09/20210903_F1_Holanda_CharlesLeclerc_01_Ferrari-1024x683.jpeg',
        link: '/'
      }]
    };
  },
  methods: {
    tranform(editions) {
      const result = [];
      editions.forEach((edition) => {
        result.push({
          year: edition.ano,
          title: edition.titulo,
          category: `${edition.ano}`,
          description: edition.descricao,
          image: edition.capa[0] ? `${process.env.VUE_APP_API}${edition.capa[0].url}` : false,
          link: `${process.env.VUE_APP_API}${edition.pdf[0].url}`
        });
      });
      return result;
    }
  },
  mounted() {
    // ano: "2021"
    // capa: []
    // created_at: "2021-11-16T19:13:31.472Z"
    // descricao: "Descrição"
    // id: 1
    // pdf: []
    // published_at: "2021-11-16T19:13:33.492Z"
    // titulo: "Edição 2021"
    // updated_at: "2021-11-16T19:13:33.507Z"

    this.$api.get('/Edicoes/?_sort=ano:DESC')
      .then((response) => {
        this.cards = this.tranform(response.data);
      });
    // this.$api.mock('/older-editions.json')
    //   .then((response) => {
    //     this.cards = response.data;
    //   });
  }
};

</script>
